// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Management', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    // appLogoImageDark: require('@/assets/images/ip/ip-mgmt-logo-dark.png'), // Will update logo in navigation menu (Branding)
    appLogoImageDark: '', // Will update logo in navigation menu (Branding)
    appLogoImageLight: '', // Will update logo in navigation menu (Branding)"
  },
  layout: {
    isRTL: false,
    skin: 'midnight', // dark, midnight, blue
    routerTransition: 'fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'static', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: false,
  },
};
