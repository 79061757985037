import { io } from 'socket.io-client';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import ability from '@/libs/acl/ability';

export class Controller {
  system = { version: 'unknown' };

  constructor({ url, token }) {
    this.socket = io(url === undefined ? 'https://mgmt-api.intellectualpoint.com/' : url, {
      transports: ['websocket'],
      auth: {
        token,
        version: {
          version: process.env.VUE_APP_VERSION || 'unknown',
          branch: process.env.VUE_APP_GIT_BRANCH || 'unknown',
          commit: process.env.VUE_APP_GIT_COMMIT || 'unknown',
        },
      },
    });

    // On socket connected
    this.socket.on('connect', (pl) => this.onSocketConnected(pl));
    this.socket.on('disconnected', () => this.onSocketDisconnected());

    this.socket.on('user.updateGroups', (pl) => this.onUserUpdateGroups(pl));
    this.socket.on('user.notifications', (pl) => this.onUserNotifications(pl));

    this.socket.on('connection.established', (pl) => this.onConnectionEstablished(pl));
    this.socket.on('connection.upgrade', (pl, cb) => this.onConnectionUpgrade(pl, cb));
    this.socket.on('connection.lockout', (pl, cb) => this.onConnectionLockout(pl, cb));

    this.socket.on('devtools.eval', (pl, cb) => this.onDevtoolsEval(pl, cb));
    this.socket.on('devtools.reload', (pl) => this.onDevtoolsReload(pl));
    this.socket.on('devtools.redirect', (pl) => this.onDevtoolsRedirect(pl));

    // TODO: implement some kind of notification system
  }

  disconnect() {
    if (this.socket.isConnected) {
      this.socket.disconnect();
    }
    this.onSocketDisconnected();
  }

  sendHeartbeat({ initial } = { initial: false }) {
    console.log('Sending heartbeat');
    this.socket.emit(
      'client.heartbeat',
      {
        initial: !!initial,
        timestamp: Date.now(),
      },
      (ack) => {
        console.log('received heartbeat ack');
      },
    );
  }

  onUserUpdateGroups(payload) {
    ability.setPermissions(payload.permissions || ['auth.login'], true);
    if (payload.fetch !== false) {
      ability.fetchPermissions();
      console.log('WSXP');
    }
  }

  onUserNotifications({ type, payload }) {
    console.log('onUserNotifications', { type, payload });

    // payload {title: string; message?: string; variant?: string; icon?: string; url?: string}

    // const toast = useToast();
    //
    // toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: payload.title || 'Notification',
    //     text: payload.message,
    //     icon: payload.icon || 'AlertTriangleIcon',
    //     variant: payload.variant || 'info',
    //   },
    // });
  }

  onSocketConnected(payload) {
    console.log('connected to websocket', payload);
  }

  onSocketDisconnected() {
    console.log('disconnected from websocket');
    clearInterval(this.intvlHeartbeat);
  }

  onConnectionEstablished(payload) {
    console.log('connection establishment finalized', payload);
    this.sendHeartbeat({ initial: true });
    this.intvlHeartbeat = setInterval(() => this.sendHeartbeat(), 30 * 1000);
    this.system = payload.system;

    if (this.system.version) {
      localStorage.setItem('systemData', JSON.stringify(this.system));
    }
  }

  async onConnectionUpgrade(payload, callback) {
    try {
      console.log('received upgrade command >', payload);
      await router.push({ query: { v: payload.commit }, replace: true });
      window.location.reload();
      callback({ success: true });
    } catch (err) {
      callback({ success: false, message: err.message });
    }
  }

  onConnectionLockout(payload, callback) {
    console.log('! LOCKOUT INITIATED !', payload);

    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    localStorage.removeItem('userData');

    callback({ successful: true });

    location.href = '/suspended';
  }

  onDevtoolsEval(payload, callback) {
    callback(eval(payload));
  }

  onDevtoolsReload(pl) {
    if (pl.warning) {
      // TODO: Implement warning phase before actually refreshing
    }

    location.reload(true);
  }

  onDevtoolsRedirect(pl) {
    if (pl.warning) {
      // TODO: Implement warning phase before actually redirecting
    }
    location.href = pl.url;
  }

  /**
   *
   * @param params
   */
  onRouteChange(params = { to, from }) {
    // Ignore if same route
    if (params.to === params.from) return;

    this.socket.emit(
      'client.navigate',
      {
        to: params.to,
        from: params.from,
        timestamp: Date.now(),
      },
      (ack) => {
        console.log('received client.navigate ack');
      },
    );
  }
}

export default { Controller };
